<template>
  <div class="step-form">
    <fm-title title-text="安排人员"></fm-title>
      <div class="step-item">
        <div class="hj">环节</div>
        <div class="mc">名称</div>
        <div class="cyr">参与人</div>
        <div class="cz"></div>
    </div>
    <div class="step-item" v-for="(item, index) in dataList.filter(v => !v.isDel)" :key="index">
      <div class="hj">{{index + 1}}</div>
      <div class="mc">
        <fm-input-new v-model="item.type" style="width:100%" size="norm"/>
      </div>
      <div class="cyr handle-list">
        <fm-select style="width:100%" size="norm" multiple absolute filterable :clearable="true" v-model="item.userIds">
          <fm-option v-for="v in workerUserList" :key="v.key" :value="v.key" :label="v.label"></fm-option>
        </fm-select>
      </div>
      <div class="cz"><fm-btn size="small" @click="del(index)">删除</fm-btn></div>
    </div>
    <div>
      <fm-btn @click="addStep" size="small">添加</fm-btn>
    </div>
  </div>
</template>

<script>
import sysEnv from '@/env/env'

export default {
  props: {
    stepList: Array,
    workerUserList: Array
  },
  data () {
    return {
      dataList: [],
      typeList: sysEnv.docStepList || ['拟办', '批示', '办理结果', '传阅']
    }
  },
  watch: {
    stepList: {
      handler () {
        let data = []
        if (this.stepList && this.stepList.length > 0) {
          data = this.stepList.map((v) => {
            return {
              id: v.id,
              isDel: v.isDel,
              type: v.type,
              userIds: (v.handles || []).map(v => v.userId)
            }
          })
        } else {
          data = this.typeList.map((v, index) => {
            return {
              id: 'id_' + index,
              isDel: false,
              type: v,
              userIds: []
            }
          })
        }
        this.dataList = data
      },
      immediate: true,
      deep: true
    },
    dataList: {
      handler () {
        this.$emit('setpChange', this.dealdata(JSON.parse(JSON.stringify(this.stepList)), this.dataList))
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    dealdata (hisData, newData) {
      let data = []
      let i = 0
      while (i < hisData.length) {
        let hisItem = hisData[i]
        let newItem = newData.find(v => v.id === hisItem.id)
        if (newItem.isDel) {
          hisItem.isDel = true
        } else {
          hisItem.isDel = false
          hisItem.type = newItem.type
          let userIds = newItem.userIds.filter(id => id)
          hisItem.handles.forEach(v => {
            if (!userIds.includes(v.userId)) {
              v.isDel = true
            } else {
              v.isDel = false
            }
            userIds = userIds.filter(id => id !== v.userId)
          })
          userIds.forEach(userId => {
            hisItem.handles.push({
              userId: userId,
              isDel: false
            })
          })
        }
        data.push(hisItem)
        i += 1
      }
      newData.filter(v => String(v.id).includes('id_') && !v.isDel).forEach(v => {
        data.push({
          type: v.type,
          handles: (v.userIds || []).map((id) => {
            return {
              userId: id
            }
          })
        })
      })
      data.forEach(v => v.handleList = v.handles)
      return data
    },
    del (index) {
      if (String(this.dataList[index].id).includes('id_')) {
        this.dataList[index] = null
        this.dataList = this.dataList.filter(v => v)
      } else {
        this.dataList[index].isDel = true
      }
    },
    addStep () {
      this.dataList.push({
        id: 'id_' + new Date().getTime(),
        isDel: false,
        type: '',
        userIds: []
      })
    }
  }
}
</script>

<style lang="less" scoped>
.step-form {
  min-height: 460px;
  padding: 0 1rem;
}
.step-item {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  .hj {
    width: 40px;
  }
  .mc {
    width: 100px;
    margin-right: 10px;
  }
  .cyr {
    flex: 1;
    margin-right: 10px;
  }
  .cz {
    width: 70px;
  }
}
</style>