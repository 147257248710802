<template>
  <sign-page
    title-text="公文管理"
    :request="request"
    table-size="large"
    :title-menus="[{key: 'new', label: '新增'}]"
    :tableActions="tableActions"
    @clickTitleMenu="chooseData=null;modal = true"
    @tableAction="tableAction"
    :column-list="columnList"
    ref="docPage"
    :table-actions-fixed="true"
    :tableActionsWidth="200"
    :need-data-file="true">
    <fm-modal v-model="modal" v-if="modal" width="1100px">
      <doc-form :id="chooseData ? chooseData.id : null" @dataUpdate="$refs.docPage.loadData()" @close="modal = false" :workerUserList="$store.getters.workerUserList" v-if="modal"></doc-form>
    </fm-modal>
    <a4-print ref="print" />
  </sign-page>
</template>

<script>
import DocForm from './cmp/form'
import DocTitle from './cmp/docTitle'
import A4Print from '@/components/a4print'

import {
  officialDocumentRequest as request
} from '../../api'

export default {
  components: {
    DocForm, A4Print
  },
  created () {
    this.$store.dispatch('loadWorkerUserList')
  },
  methods: {
    async tableAction (parm) {
      this.chooseData = parm.data
      let action = parm.action
      if (action === 'edit_all') {
        this.modal = true
      } else if (action === 'over') {
        this.chooseData.status = 'over'
        await request.update(this.chooseData.id, this.chooseData)
        this.$notice.success({
          title: '系统提示',
          desc: '公文已结束。'
        })
        this.$refs.docPage.loadData()
      } else if (action === 'detail') {
        this.$router.push({
          name: 'hrms.doc.detail',
          query: {
            id: this.chooseData.id
          }
        })
      } else if (action === 'back') {
        await request.back(this.chooseData.id)
        this.$notice.success({
          title: '系统提示',
          desc: '公文可继续处理。'
        })
        this.$refs.docPage.loadData()
      } else if (action === 'cover') {
        this.printCover()
      }
    },
    async printCover () {
      const res = await request.getDetail({
        officialDocumentId: this.chooseData.id
      })
      if (res.length) {
        this.$refs.print.print('doc-cover', '来文处理标签', (tpl) => {
          const steps = res[0].steps
          const html = steps.map(({ type, handles }) => {
            const content = handles.filter(v => v.status === 'over').map(item => {
              return (item.content ? (item.content + ' ') : '') + item.handleUserName + ' ' + (item.handleTime ? item.handleTime.slice(0, 10) : '')
            }).join('<br />')
            return '<div class="row">'
              + '<div class="type">' + type + '</div>'
              + '<div class="text">' + content + '</div>'
            + '</div>'
          }).join('')
          return tpl.replace('<!--html-->', html)
        })
      }
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'edit_all',
        label: '编辑',
        show (data) {
          return data.status === 'doing'
        }
      },
      {
        key: 'detail',
        label: '查看'
      },
      {
        key: 'back',
        label: '继续处理',
        show (data) {
          return data.status === 'over'
        }
      },
      {
        key: 'cover',
        label: '打印封面',
        show (data) {
          return data.status === 'over'
        }
      },
      {
        key: 'over',
        label: '归档',
        show (data) {
          return data.status === 'doing'
        }
      },
      {
        key: 'del',
        label: '删除',
        show (data) {
          return data.status === 'doing'
        }
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '编码',
          sort: true,
          field: 'id'
        },
        {
          title: '文件名称',
          sort: true,
          field: 'title',
          render: (h, row) => {
            return h(DocTitle, {
              props: {
                title: row.title
              }
            })
          }
        },
        {
          title: '来文级别',
          sort: true,
          field: 'sendLevel'
        },
        {
          title: '来文单位',
          sort: true,
          field: 'sendOrgName'
        },
        {
          title: '来文份数',
          sort: true,
          field: 'num',
          dataType: Number
        },
        {
          title: '来文日期',
          sort: true,
          field: 'sendTime',
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.sendTime ? rowData.sendTime.slice(0, 10) : '-')
          }
        },
        {
          title: '来文号',
          sort: true,
          field: 'sendCode'
        },
        {
          title: '收文日期',
          sort: true,
          field: 'getTime',
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.getTime ? rowData.getTime.slice(0, 10) : '-')
          }
        },
        {
          title: '归入案卷号',
          sort: true,
          field: 'getCode',
          render: (h, rowData) => {
            return h('div', rowData && rowData.getCode ? rowData.getCode : rowData.id)
          }
        },
        {
          title: '状态',
          sort: true,
          field: 'status',
          render: (h, rowData) => {
            return h('div', rowData && rowData.status === 'doing' ? '未结束' : '已结束')
          }
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: request,
      chooseData: null,
      modal: false
    }
  }
}
</script>
