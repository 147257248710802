<style>
  .print-preview {
    background-color: #EEE;
    min-height: 70vh;
  }
  .print-preview iframe {
      border: none;
      box-shadow: 0 0 5px hsl(0deg 0% 0% / 10%);
      display: block;
      background-color: #FFF;
      width: 21cm;
      height: 70vh;
      margin: 10px auto;
      padding: 0.254cm 0.27cm 0.254cm 0.27cm;
      box-sizing: border-box;
  }
</style>

<template>
  <fm-modal v-model="show" :title="title" width="30cm">
    <div class="print-preview" v-loadingx="loading.load" ref="wrap"></div>
    <div slot="footer" class="modal-footer-btns" :mask-closable="false">
      <fm-btn :disabled="loading.load" @click="$iframe.contentWindow.print()">打印</fm-btn>
      <fm-btn :disabled="loading.load" @click="show = false">取消</fm-btn>
    </div>
  </fm-modal>
</template>

<script>

const tplMap = Object.freeze({
  contract: { path: '/static/document/contract.html' },
  resume: { path: '/static/document/resume.html' },
  'doc-cover': { path: '/static/document/doc-cover.html' }
});

export default {
  data () {
    return {
      $iframe: null,
      show: false,
      title: null,
      loading: {
        load: false
      }
    }
  },
  methods: {
    print (type, title, fn) {
      if (this.$iframe) {
        this.$iframe.remove()
      }

      this.title = title
      this.show = true
      this.loading.load = true

      this.$iframe = document.createElement('iframe')
      this.$iframe.src = tplMap[type].path
      this.$iframe.addEventListener('load', () => {
        this.loading.load = false
        this.$iframe.contentDocument.body.innerHTML = fn(this.$iframe.contentDocument.body.innerHTML)
      });

      this.$refs.wrap.appendChild(this.$iframe)
    }
  }
}
</script>
